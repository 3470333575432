import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import SV from 'server-vars';
import { getBuyerToken } from 'dibs-cookie-jar';
import { createFetcher } from 'dibs-relay-network-layer/client';

/**
    A note on authenticated SSR:
    - If the SSR call is un-authenticated, use the data from `dbl.relayData` (automatically injected into SV).
    - If the SSR requires authentication (and a custom relay environment), inject the envrionment's JSON into SV with
      the `abt` namespace so it can live alongside the DBL result -- `dbl.relayData` and `abt.relayData`.

    The two namepsaces are necessary on authenticated calls because `dbl.relayData` includes SSR fetch results for
    the header and footer, among other things.  Injecting the custom relayData into `dbl.relayData` clobbers
    enough that content will flash & require a network call on page load.

    @arg {boolean} [useDbl] - If no value is passed in, use the `abt.relayData` result (for auth'd SSR); if true, use `dbl.relayData` (for un-auth'd SSR)
*/
export function getEnvironment(useDbl: boolean = false): Environment {
    const records = SV.get(`${useDbl ? 'dbl' : 'abt'}.relayData`) || {};

    const source = new RecordSource(records);
    const store = new Store(source);
    const network = Network.create(
        createFetcher({
            userType: 'buyer',
            getQueries: () => ({
                ...getBuyerToken(document.cookie),
                locale: SV.get('locale'),
            }),
        })
    );

    return new Environment({
        network,
        store,
    });
}
